import React from "react";
import MainPost from "../Components/MainPost";
import CardSeguros from "../Components/CardSeguros";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  main: {
    marginBottom: theme.spacing(8),
  },
}));

const setCards = [
  {
    imgUrl:
      "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/empresas/acidentes-pessoais.jpg",
    imgTitle: "Acidentes Pessoais",
    cardTitle: "Acidentes Pessoais",
    cardDescription: "",
  },
  {
    imgUrl:
      "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/empresas/acidentes-trabalho.jpg",
    imgTitle: "Acidentes de Trabalho",
    cardTitle: "Acidentes de Trabalho",
    cardDescription: "",
  },
  {
    imgUrl:
      "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/empresas/saude.jpg",
    imgTitle: "Saúde",
    cardTitle: "Saúde",
    cardDescription: "",
  },
  {
    imgUrl:
      "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/empresas/vida.jpg",
    imgTitle: "Vida",
    cardTitle: "Vida",
    cardDescription: "",
  },
  {
    imgUrl:
      "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/empresas/multirriscos.jpg",
    imgTitle: "Multirriscos",
    cardTitle: "Multirriscos",
    cardDescription: "",
  },
  {
    imgUrl:
      "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/empresas/frota.jpg",
    imgTitle: "Seguros de Frota",
    cardTitle: "Frota",
    cardDescription: "",
  },
  {
    imgUrl:
      "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/empresas/responsabilidade-civil.jpg",
    imgTitle: "Responsabilidade Civil",
    cardTitle: "Responsabilidade Civil",
    cardDescription: "",
  },
  {
    imgUrl:
      "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/empresas/transportes.jpg",
    imgTitle: "Transportes",
    cardTitle: "Transportes",
    cardDescription: "",
  },
  {
    imgUrl:
      "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/empresas/construcao.jpg",
    imgTitle: "Construção",
    cardTitle: "Construção",
    cardDescription: "",
  },
  {
    imgUrl:
      "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/empresas/financeiros.jpg",
    imgTitle: "Financeiros",
    cardTitle: "Financeiros",
    cardDescription: "",
  },
];

const mainFeaturedPost = {
  title: "Seguros Empresas",
  description: "Proteja o seu negócio e os seus colaboradores",
  image: "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/empresas.jpg",
  imgText: "Seguros Empresas",
  linkText: "",
};

const SegurosEmpresasPage = () => {
  const classes = useStyles();

  const getCards = (card) => {
    return (
      <Grid item xs={12} sm={4} key={`Grid-${card.cardTitle}`}>
        <CardSeguros {...card} />
      </Grid>
    );
  };

  return (
    <div className={classes.main}>
      <MainPost post={mainFeaturedPost} />

      <Grid item container className={classes.main}>
        <Grid item xs={1} sm={2} />
        <Grid item xs={10} sm={8}>
          <Typography
            variant="subtitle1"
            color="inherit"
            align="center"
            paragraph
          >
            Dispomos de várias soluções adequadas à sua empresa, que garantem a
            continuidade do seu negócio caso suceda alguma adversidade.
          </Typography>
        </Grid>
        <Grid item xs={1} sm={2} />
      </Grid>
      <Grid item container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container spacing={4}>
            {setCards.map((card) => getCards(card))}
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </div>
  );
};

export default SegurosEmpresasPage;
