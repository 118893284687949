import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Typography } from "@material-ui/core";

// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

// function getModalStyle() {
//   const top = 50 + rand();
//   const left = 50 + rand();

//   return {
//     top: `${top}%`,
//     left: `${left}%`,
//     transform: `translate(-${top}%, -${left}%)`,
//   };
// }

const useStyles = makeStyles((theme) => ({
  paper: {
    top: "8%",
    left: "12.5%",
    position: 'absolute',
    width: "75%",
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: "scroll", 
    maxHeight: "90%"
  },
}));

export default function SimpleModal() {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  //const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  

  const body = (
    <div className={classes.paper}>
    <h2>Política de Privacidade</h2>
    <h3>Introdução</h3>
        <p>Na sequência da implementação do novo Regulamento Geral de Proteção de Dados
(Regulamento EU 2016/679 do Parlamento e do Conselho de 27 de abril de 2016 - RGPD) e a
Lei n.º 58/2019, de 08 de agosto, que assegura a execução, na ordem jurídica nacional, do
RGPD, torna-se necessária a criação da presente política de privacidade. Esta política identifica
o responsável pelo tratamento dos dados pessoais, descreve a forma como se procede ao
tratamento dos dados pessoais e informa os direitos dos titulares dos dados.</p>

<h3>Quem trata os dados pessoais</h3>
<p>O responsável pelo tratamento dos dados pessoais é a <b>JDOISJ Sociedade de Mediação de Seguros Lda</b>,
com número de identificação de pessoa coletiva 508561680, capital Social de 5.000 €,
mediador de seguros inscrito em 19-09-2008, na ASF-Autoridade de Supervisão de Seguros e
Fundos de Pensões com a categoria de Agente de Seguros, sob o n.º 408282523, com
autorização para os ramos Vida e Não Vida, verificável em www.asf.com.pt.</p>


<h3>Recolha e tratamento de dados pessoais</h3>
<p>Os dados pessoais recolhidos no site eletrónico da <b>JDOISJ Sociedade de Mediação de Seguros Lda</b>, consistem em dados de
identificação e dados de contacto, necessários para dar resposta aos pedidos de informação
solicitados pelos utilizadores.</p>
<p>Assim, no âmbito da atividade de mediação de seguros, e se for solicitado pelos utilizadores,
os referidos dados serão inseridos nas plataformas informáticas das seguradoras para as quais
prestamos serviços, com vista à realização de simulações de seguros.</p>
<p>A <b>JDOISJ Sociedade de Mediação de Seguros Lda</b> compromete-se no âmbito do processo apresentado, a cumprir o disposto na Lei
de Proteção de Dados Pessoais, bem como na demais legislação aplicável designadamente, a
não copiar, reproduzir, adaptar, modificar, alterar, apagar, destruir, difundir, transmitir,
divulgar ou por qualquer outra forma colocar à disposição de terceiros, os dados pessoais a
que tenham tido acesso ou que lhes sejam transmitidas no âmbito deste processo, em que
para tal tenha sido expressamente autorizada, comprometendo-se a utilizá-los exclusivamente
para finalidades determinantes de recolha, abstendo-se de qualquer uso fora do contexto,
quer em benefício próprio, quer de terceiros.</p>
<p>Os dados pessoais serão conservados de acordo com as respetivas finalidades e respeitando os
prazos legais aplicáveis. Assim, e sempre que não exista uma exigência legal específica, os
dados serão armazenados e conservados apenas pelo período adequado e na medida do
necessário ao âmbito das finalidades para as quais foram recolhidos, exceto conforme ponto
abaixo, se for exercido, dentro dos limites legais, o direito de oposição, direito ao apagamento
ou caso seja retirado o consentimento.</p>

<h3>Direitos dos titulares dos dados</h3>
<p>Nos termos da lei aplicável, os titulares dos dados têm os seguintes direitos:</p>
<ol>
  <li>Retirar o consentimento relativamente ao tratamento efetuado dos seus dados pessoais;</li>
  <li>Opor-se à continuação de tratamento dos seus dados pessoais;</li>
  <li>Solicitar ao responsável pelo tratamento de dados pessoais o acesso aos mesmos, bem como a respetiva retificação ou apagamento, incluindo o exercício do “direito a ser esquecido”;</li>
<li>Apresentar queixa à Comissão Nacional de Proteção de Dados;</li>
<li>Ser informado, a pedido, sobre as finalidades do tratamento, as categorias dos dados envolvidos, a identidade dos destinatários a quem tenham sido divulgados e o período de conservação dos seus dados pessoais;</li>
<li>O direito de consulta, acesso, retificação, atualização ou eliminação dos meus dados pessoais disponibilizados no âmbito dos registos e do Processo de
Fiscalização apresentados ao abrigo do presente Regulamento, mediante comunicação, para o efeito, por correio eletrónico enviado para o email do encarregado de proteção de dados - joao.tavares@jdoisj.pt.</li>
</ol>

<h3>Alterações à política de privacidade</h3>
<p>A <b>JDOISJ Sociedade de Mediação de Seguros Lda</b> poderá alterar ou atualizar a presente política de privacidade em função de novas
exigências legais ou regulamentares, bem como na sequência de melhorias das
funcionalidades do seu website.</p>
    </div>
  );

  return (
    <>
    <Typography variant="overline" gutterBottom align="right"  style={{ cursor: 'pointer' }} onClick={handleOpen}>Política de Privacidade</Typography>
    <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-politica-privacidade"
          aria-describedby="open-modal-politica-privacidade"
          sx={{overflowY: 'scroll'}} disableScrollLock={false}
      >
              {body}
          </Modal>
    </>
    
  );
}