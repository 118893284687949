import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1, 1.5),
    color: "#fff",
  },
}));

const NavItem = ({ url, title, color, variant }) => {
  const classes = useStyles();

  return (
    <Link to={url} style={{ textDecoration: "none" }}>
      <Button variant={variant} className={classes.button}>
        {title}
      </Button>
    </Link>
  );
};

export default NavItem;
