import React, { useState } from "react";
import emailjs from "emailjs-com";

import { TextField, Grid, Button, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    //marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ContactForm = () => {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [showNotification, setShowNotification] = useState({ status: false });

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const formValidation = (which) => {
    let isValid = true;
    const errors = {};
    if (name.trim().length < 2) {
      isValid = false;
      errors.name = "Por favor, insira um nome com pelo menos 2 caracteres";
    }
    if (phone.length < 9) {
      isValid = false;
      errors.phone = "Por favor, insira um número de telemóvel válido";
    }

    if (!validateEmail(email)) {
      isValid = false;
      errors.email = "Por favor, insira um email válido";
    }

    if (message.trim().length < 10) {
      isValid = false;
      errors.message =
        "Por favor, insira uma mensagem com pelo menos 10 caracteres";
    }
    console.log("errors:", errors);
    setErrors(errors);
    return isValid;
  };

  function sendEmails(e) {
    e.preventDefault();

    const isValid = formValidation();

    console.log("isValid?", isValid);
    if (isValid) {
      console.log("form is ok!!!");

      emailjs
        .sendForm(
          "service_gmail",
          "template_contactsForm",
          e.target,
          "user_y39RVi4KM8SWCntBBbf6M"
        )
        .then(
          (result) => {
            setShowNotification({
              status: true,
              severity: "success",
              message:
                "A sua mensagem foi enviada com sucesso, iremos responder assim que possível.",
            });
            setName("");
            setEmail("");
            setPhone("");
            setMessage("");
            e.target.reset();
            console.log(result.text);
          },
          (error) => {
            setShowNotification({
              status: true,
              severity: "error",
              message:
                "De momento, não é possivel enviar a sua mensagem. Por favor, tente mais tarde ou contacte-nos por telefone",
            });
          }
        );
    } else {
      setShowNotification({
        status: true,
        severity: "warning",
        message:
          "Os campos do formulário contém erros. Por favor, corrija e volte a enviar",
      });
    }
  }

  const sentMessage = ({ severity, message }) => {
    return <Alert severity={severity}>{message}</Alert>;
  };

  return (
    <>
      <div>
        {showNotification.status ? sentMessage(showNotification) : null}
      </div>
      <form className={classes.form} onSubmit={sendEmails}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align="left">
              Envie-nos uma mensagem:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="name"
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="Nome"
              autoFocus
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              helperText={errors.name}
              error={errors.name ? true : false}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              helperText={errors.email}
              error={errors.email ? true : false}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              InputProps={{ type: "number" }}
              id="phone"
              label="Telemóvel"
              name="phone"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              helperText={errors.phone}
              error={errors.phone ? true : false}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="message"
              label="Mensagem"
              name="message"
              multiline
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              helperText={errors.message}
              error={errors.message ? true : false}
            />
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              className={classes.submit}
            >
              Enviar
            </Button>
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </form>
    </>
  );
};

export default ContactForm;
