import React from "react";
import MediaCard from "../Components/Card";
import Grid from "@material-ui/core/Grid";
import MainPost from "../Components/MainPost";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  main: {
    marginBottom: theme.spacing(8),
  },
}));

const setCards = [
  {
    imgUrl: "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/particulares.jpg",
    imgTitle: "Seguros Particulares",
    cardTitle: "Particulares",
    cardDescription: "Consulte a oferta para o cliente individual",
    firstLink: "/seguros-particulares",
  },
  {
    imgUrl: "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/empresas.jpg",
    imgTitle: "Seguros Empresas",
    cardTitle: "Empresas",
    cardDescription: "Descubra o leque de soluções para o cliente empresarial",
    firstLink: "/seguros-empresas",
  },
];

const HomePage = () => {
  const classes = useStyles();

  const getCards = (card) => {
    return (
      <Grid item xs={12} sm={6} key={card.imgTitle}>
        <MediaCard {...card} />
      </Grid>
    );
  };

  const mainFeaturedPost = {
    title: "",
    description:
      "Porque na vida há acontecimentos inesperados, trabalhamos para que esteja preparado para os enfrentar!",
    image:
      "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/homepage.jpg?force_format=jpeg&q=40", //https://source.unsplash.com/random
    imgText: "Página principal JDOISJ",
    linkText: "",
  };

  return (
    <div className={classes.main}>
      <MainPost post={mainFeaturedPost} />
      <Grid item container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container spacing={4}>
            {setCards.map((card) => getCards(card))}
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </div>
  );
};

export default HomePage;
