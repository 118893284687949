import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Modal from './Modal'
const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#09417E",
    color: "#fff",
    //width: "100%",
    marginTop: "auto",
    padding: theme.spacing(3, 2),
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <>
      <footer className={classes.footer}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" spacing={1}>
          <Grid item sm={1}/>
          <Grid item xs={12} sm={7} >
            <Typography variant="caption" gutterBottom align="center">
              2021 @ JDOISJ Sociedade de Mediação de Seguros
            </Typography>
          </Grid>
          {/* <Grid item sm={2} md={4}/> */}
          <Grid item xs={12} sm>
              <Modal />
          </Grid>
          <Grid item xs={12} sm >
            <Typography variant="overline" gutterBottom align="right">
              <Link
                href="https://www.livroreclamacoes.pt/inicio/"
                target="_blank"
                rel="noopener"
                color="inherit"
              >
                Livro de Reclamações Electrónico
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </footer>
    </>
  );
}
