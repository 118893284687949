import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  media: {
    height: 140,
  },
});

export default function CardSeguros({
  imgUrl,
  imgTitle,
  cardTitle,
  cardDescription,
}) {
  const classes = useStyles();

  return (
    <Card>
      <CardActionArea>
        <CardMedia className={classes.media} image={imgUrl} title={imgTitle} />
        <CardContent>
          <Typography variant="subtitle1">{cardTitle}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
