import React from "react";
import NavItem from "./NavItem";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  MenuItem,
  Menu,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuIcon from "@material-ui/icons/Menu";
import { withRouter } from "react-router-dom";

const paginas = [
  { title: "Quem somos", url: "/quem-somos", color: "white", variant: "text" },
  {
    title: "Seguros Particulares",
    url: "/seguros-particulares",
    color: "white",
    variant: "text",
  },
  {
    title: "Seguros Empresas",
    url: "/seguros-empresas",
    color: "black",
    variant: "text",
  },
  { title: "Contactos", url: "/contactos", color: "black", variant: "text" },
  // {
  //   title: "Pedir simulação",
  //   url: "/pedir-simulacao",
  //   color: "second",
  //   variant: "outlined",
  // },
];

const useStyles = makeStyles((theme) => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
}));

const Header = ({ history }) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery("(min-width:900px)");
  //const isFullDesktop = useMediaQuery("(min-width:1075px)");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClick = (pageUrl) => {
    history.push(pageUrl);
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <AppBar position="static" color="primary" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography
            key="title"
            variant="h6"
            color="inherit"
            className={classes.toolbarTitle}
            onClick={() => handleMenuClick("/")}
          >
            JDOISJ Seguros
          </Typography>
          {/* {isFullDesktop && (
            <nav>
              {paginas.map((item) => {
                return (
                  <NavItem {...item} key={`NavItem-Desktop-${item.title}`} />
                );
              })}
            </nav>
          )} */}
          {isDesktop && (
            <nav>
              {paginas.map((item) => {
                if (item.color !== "primary") {
                  return <NavItem {...item} />;
                }
              })}
            </nav>
          )}
          {!isDesktop && (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={() => setAnchorEl(null)}
              >
                {paginas.map((item) => {
                  return (
                    <MenuItem
                      onClick={() => handleMenuClick(item.url)}
                      key={`MenuItem-Mobile-${item.title}`}
                    >
                      {item.title}
                    </MenuItem>
                  );
                })}
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withRouter(Header);
