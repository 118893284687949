import React from "react";
import MainPost from "../Components/MainPost";
import CardSeguros from "../Components/CardSeguros";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  main: {
    marginBottom: theme.spacing(8),
  },
}));

const setCards = [
  {
    imgUrl:
      "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/particulares/vida.jpg",
    imgTitle: "Vida",
    cardTitle: "Vida",
    cardDescription: "",
  },
  {
    imgUrl:
      "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/particulares/saude.jpg",
    imgTitle: "Saúde",
    cardTitle: "Saúde",
    cardDescription: "",
  },
  {
    imgUrl:
      "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/particulares/multirriscos.jpg",
    imgTitle: "Multirriscos",
    cardTitle: "Multirriscos",
    cardDescription: "",
  },
  {
    imgUrl:
      "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/particulares/acidentes-pessoais.jpg",
    imgTitle: "Acidentes Pessoais",
    cardTitle: "Acidentes Pessoais",
    cardDescription: "",
  },
  {
    imgUrl:
      "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/particulares/viagem.jpg",
    imgTitle: "Viagem e Lazer",
    cardTitle: "Viagem e Lazer",
    cardDescription: "",
  },
  {
    imgUrl:
      "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/particulares/acidentes-trabalho.jpg",
    imgTitle: "Acidentes de Trabalho",
    cardTitle: "Acidentes de Trabalho",
    cardDescription: "",
  },
  {
    imgUrl:
      "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/particulares/auto.jpeg",
    imgTitle: "Automóvel",
    cardTitle: "Automóvel",
    cardDescription: "",
  },

  {
    imgUrl:
      "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/particulares/responsabilidade-civil.jpg",
    imgTitle: "Responsabilidade Civil",
    cardTitle: "Responsabilidade Civil",
    cardDescription: "",
  },
  {
    imgUrl:
      "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/particulares/financeiros.jpg",
    imgTitle: "Financeiros",
    cardTitle: "Financeiros",
    cardDescription: "",
  },
  {
    imgUrl:
      "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/particulares/animais.jpg?force_format=jpeg&q=75",
    imgTitle: "Animais Domésticos",
    cardTitle: "Animais Domésticos",
    cardDescription: "",
  },
];

const mainFeaturedPost = {
  title: "Seguros Particulares",
  description: "Viva em segurança e garanta a proteção da sua família",
  image: "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/particulares-capa2.jpg",
  imgText: "Seguros Particulares",
  linkText: "",
};

const SegurosParticularesPage = () => {
  const classes = useStyles();

  const getCards = (card) => {
    return (
      <Grid item xs={12} sm={4} key={`Grid-${card.cardTitle}`}>
        <CardSeguros {...card} />
      </Grid>
    );
  };

  return (
    <div className={classes.main}>
      <MainPost post={mainFeaturedPost} />

      <Grid item container className={classes.main}>
        <Grid item xs={1} sm={2} />
        <Grid item xs={10} sm={8}>
          <Typography
            variant="subtitle1"
            color="inherit"
            align="center"
            paragraph
          >
            Porque existem inúmeros acontecimentos que podem afetar a sua
            estabilidade financeira, estamos deste lado para o aconselhar nas
            soluções que melhor se adaptam a si.
            {/* Porque os imprevistos acontecem, analisamos as suas necessidades
            paralhe apresentar as soluções queasseguram a melhor proteção para
            si e para a sua família em qualquer etapa da vida. Dispomos de uma
            vasta gama de seguros de proteçãopessoal, patrimonial e
            responsabilidades. */}
          </Typography>
        </Grid>
        <Grid item xs={1} sm={2} />
      </Grid>
      <Grid item container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container spacing={4}>
            {setCards.map((card) => getCards(card))}
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </div>
  );
};

export default SegurosParticularesPage;
