import React from "react";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import {
  Typography,
  Paper,
  Card,
  Grid,
  Link,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fdfdff",
    minHeight: "150px"
  },
  pageHeader: {
    padding: theme.spacing(3),
    display: "flex",
    marginBottom: theme.spacing(2),
  },
  pageIcon: {
    display: "inline-block",
    padding: theme.spacing(2),
    color: "#3c44b1",
  },
  pageTitle: {
    paddingLeft: theme.spacing(4),
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
}));

const ContactOptions = () => {
  const classes = useStyles();

  return (
    <>
      <Grid item container spacing="1">
        <Grid item xs={12} md={4}>
          <Paper elevation={0} square className={classes.root}>
            <div className={classes.pageHeader}>
              <Card className={classes.pageIcon}>
                <RoomIcon fontSize="large" color="primary" />
              </Card>
              <div className={classes.pageTitle}>
                <Typography variant="h6" component="div">
                  Morada
                </Typography>
                <Typography variant="subtitle2" component="div">
                  Rua José Gustavo Pimenta nº 29
                </Typography>
                <Typography variant="subtitle2" component="div">
                  3750 - 155 Águeda
                </Typography>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={0} square className={classes.root}>
            <div className={classes.pageHeader}>
              <Card className={classes.pageIcon}>
                <PhoneIcon fontSize="large" color="primary" />
              </Card>
              <div className={classes.pageTitle}>
                <Typography variant="h6" component="div">
                  Contactos
                </Typography>
                <Typography variant="subtitle2" component="div">
                  <Link color="inherit" href="tel: 234 004 560">
                    234 004 560
                  </Link> &nbsp;
                  <Typography variant="caption" display="inline" gutterBottom>
                   (Chamada para a rede fixa nacional)
                  </Typography>
                  <br/>
                  <Link color="inherit" href="tel: 965 804 536">
                    965 804 536 
                  </Link> &nbsp;
                  <Typography variant="caption" display="inline">
                  (Chamada para rede móvel nacional)
                  </Typography>
                </Typography>
                <Typography variant="subtitle2" component="div">
                  <Link
                    color="inherit"
                    href="mailto:geral@jdoisj.pt"
                    target="_blank"
                  >
                    geral@jdoisj.pt
                  </Link>
                </Typography>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={0} square className={classes.root}>
            <div className={classes.pageHeader}>
              <Card className={classes.pageIcon}>
                <AccessTimeIcon fontSize="large" color="primary" />
              </Card>
              <div className={classes.pageTitle}>
                <Typography variant="h6" component="div">
                  Horário de Funcionamento
                </Typography>
                <Typography variant="subtitle2" component="div">
                  Manhã: 09h00 - 12h30
                </Typography>
                <Typography variant="subtitle2" component="div">
                  Tarde: 14h00 - 18h30
                </Typography>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactOptions;
