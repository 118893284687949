import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";

import Header from "./Components/Header/Header";
import HomePage from "./Pages/Homepage";
import AboutPage from "./Pages/About";
import Footer from "./Components/Footer";
import ContactsPage from "./Pages/Contacts";
import SegurosEmpresasPage from "./Pages/SegurosEmpresas";
import SegurosParticularesPage from "./Pages/SegurosParticulares";
import PedidoCotacao from "./Pages/PedidoCotacao";
import CssBaseline from "@material-ui/core/CssBaseline";

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Router>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <Header />
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/quem-somos" component={AboutPage} />
            <Route
              exact
              path="/seguros-particulares"
              component={SegurosParticularesPage}
            />
            <Route
              exact
              path="/seguros-empresas"
              component={SegurosEmpresasPage}
            />
            <Route exact path="/contactos" component={ContactsPage} />
            {/* <Route exact path="/pedir-simulacao" component={PedidoCotacao} /> */}
            <Route component={HomePage} />
          </Switch>
          {/* <GetWhatsApp /> */}
          <Footer />
        </div>
      </Router>
    </React.Fragment>
  );
}

export default App;
