import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  media: {
    height: 140,
  },
});

const MediaCard = ({
  imgUrl,
  imgTitle,
  cardTitle,
  cardDescription,
  firstLink,
}) => {
  const classes = useStyles();

  return (
    <Card>
      <CardActionArea>
        <CardMedia className={classes.media} image={imgUrl} title={imgTitle} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {cardTitle}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {cardDescription}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Link to={firstLink} style={{ textDecoration: "none" }}>
          <Button size="small" color="primary">
            Consultar
          </Button>
        </Link>
        {/* <Button size="small" color="primary">
          Simular
        </Button> */}
      </CardActions>
    </Card>
  );
};

export default MediaCard;
