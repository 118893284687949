import React from "react";
import MainPost from "../Components/MainPost";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Container } from "@material-ui/core";

const mainFeaturedPost = {
  title: "",
  description: "",
  image: "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/aboutus.jpg",
  imgText: "",
  linkText: "",
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginBottom: theme.spacing(8),
  },
}));

const AboutPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <MainPost post={mainFeaturedPost} />
      <Container className={classes.main}>
        <div>
          <Typography variant="h4" gutterBottom>
            Quem somos
          </Typography>
          <Typography variant="body1" paragraph gutterBottom align="justify">
            A JDOISJ - Sociedade de Mediação de Seguros Lda, foi constituída em
            julho de 2008, por Jorge Tavares e o seu filho João Tavares, e
            desenvolve a sua atividade em estreita ligação com o setor
            segurador.
          </Typography>
          <Typography variant="body1" paragraph gutterBottom align="justify">
            Tudo começou em 1984, quando Jorge Tavares iniciou a sua carreira de
            mediador de seguros, criando e fortalecendo a sua relação com
            clientes e parceiros, constituindo até hoje uma carteira assente na
            confiança e dedicação.
          </Typography>
          <Typography variant="h4" gutterBottom>
            Missão
          </Typography>
          <Typography variant="body1" paragraph gutterBottom align="justify">
            Na JDOISJ, a nossa principal prioridade é o compromisso com o
            cliente, num ambiente de proximidade e acompanhamento, ouvindo as
            suas preocupações, avaliando as suas necessidades e propondo as
            melhores soluções do mercado.
          </Typography>
          <Typography variant="body1" paragraph gutterBottom align="justify">
            Estamos conscientes que vivemos num mundo dinâmico e exigente, pelo
            que trabalhamos diariamente para nos adaptarmos aos desafios que nos
            são colocados, em processo de constante aprendizagem, e assim,
            adquirindo competências de forma a prestarmos cada vez mais, um
            melhor serviço.
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default AboutPage;
