import React from "react";
import ContactForm from "../Components/Contacts/ContactForm";
import ContactOptions from "../Components/Contacts/ContactOptions";
import MainPost from "../Components/MainPost";

import { Grid } from "@material-ui/core";
import { Skeleton, Alert } from "@material-ui/lab";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  margins: {
    marginBottom: theme.spacing(8),
  },
  iframe: {
    width: "100%",
  },
}));

const mainFeaturedPost = {
  title: "Contacte-nos",
  description: "Se pretender algum esclarecimento ou simulação",
  image: "https://adhdyxgcrq.cloudimg.io/v7/jdoisj.pt/imgs/contacts.jpg",
  imgText: "Contacto",
  linkText: "",
};

const ContactsPage = () => {
  const classes = useStyles();

  const [iframeLoaded, setIframeLoaded] = React.useState(false);
  const onLoadIframe = () => {
    setIframeLoaded(true);
  };

  return (
    <div className={classes.margins}>
      <MainPost post={mainFeaturedPost} />
      <Grid container spacing={2}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <ContactOptions />
          {/* <Alert severity="info">
            De momento, face à situação pandémica em que vivemos, encontramo-nos
            em teletrabalho, pelo que priveligiamos o contacto telefónico e
            online.
          </Alert> */}
        </Grid>
        <Grid item xs={1} />
        <Grid container item xs={12}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Grid container spacing={6} justify="space-between">
              <Grid item xs={12} md={6}>
                {!iframeLoaded ? (
                  <Skeleton variant="rect" animation="wave" height={350} />
                ) : null}
                <iframe
                  title="Maps of JDOISJ"
                  height="350"
                  id="gmap_canvas"
                  //src="https://maps.google.com/maps?q=jdoisj%20sociedade%20de%20media%C3%A7%C3%A3o&t=&z=17&ie=UTF8&iwloc=&output=embed"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4914.199977447016!2d-8.445366461815885!3d40.579320829566115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd230a2e611d47f7%3A0xaf1382a8ac45c197!2sJDOISJ%20Sociedade%20de%20Media%C3%A7%C3%A3o%20de%20Seguros%20LDA!5e0!3m2!1sen!2spt!4v1611427983111!5m2!1sen!2spt"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  className={classes.iframe}
                  onLoad={() => onLoadIframe()}
                ></iframe>
              </Grid>
              <Grid item xs={12} md={6}>
                <ContactForm />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactsPage;
